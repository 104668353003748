.admin-panel-login {
    background: #FBFCFC;
    border: 1px solid #F5D034;
    max-width: 450px;
    margin: auto;
    border-radius: 5px;
}

.admin-panel-login label {
    font-weight: 600;
    font-size: 16px;
    font-family: 'ibm-plex-mono', sans-serif;
}

.admin-control.form-control {
    border-radius: 0px;
    border-color: #ccc;
}