// Variables
@import "variables";

#admin-nav {
    width: 300px;
    height: 100vh;
    background: #FBFCFC;
    position: fixed;
    padding-top: 72px;
    top: 0px;
    left: 0px;
    z-index: 4;
    box-shadow: -4px 0px 25px 0px rgba(0, 0, 0, 0.25);

    ul.menu {
        padding-top: 40px;
        list-style: none;
        padding: 30px;

        > li {
            text-transform: uppercase;
            font-size: 17px;
            font-weight: 500;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #888;
            font-family: 'ibm-plex-mono', sans-serif;
        }

        > li:before {
            content: '>';
            float: right;
        }

        > li.active {
            font-weight: 600;
        }

        li.logout {
            border-bottom: none;
            padding-top: 25px;
            font-size: 15px;
        }

        li.logout:before {
            display: none;
        }

        li a {
            color: inherit;
        }

        ul.submenu {
            font-weight: 500;
            list-style-type: circle;
            padding-left: 21px;

            li {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            li.active {
                list-style-type: disc;
                font-weight: 600;
            }

            li:first-child {
                margin-top: 5px;
            }
        }
    }
}

#admin-content {
    padding-left: 315px;
    width: 100%;
    padding-top: 15px;
    padding-right: 15px;
}

#menu-toggle {
    position: fixed;
    top: 13px;
    left: 27px;
    font-size: 28px;
    z-index: 9999;
}

#admin-nav.collapsed {
    left: -300px;
}

#admin-content.collapsed {
    padding-left: 15px;
}

#admin-content.tour-builder {
    padding: 0;
    margin-top: -18px;
    margin-bottom: -1.5rem;
}

#admin-nav.transition, #admin-content.transition {
    transition: 300ms ease-out;
}

.stat-box {
    border: 1px solid theme-color("primary");
    border-radius: 5px;
    margin-bottom: 25px;
    padding-top: 10px;
    padding-bottom: 10px;

    span {
        font-weight: 700;
        color: #333;
        font-size: 46px;
    }

    p {
        font-family: 'ibm-plex-mono', sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
    }

    .star-rating {
        font-size: 20px;
        color: theme-color("primary");
    }
}

.stat-box.disabled {
    opacity: 0.5;
}

select.admin-control {
    border: 2px solid #8b8b8b;
    border-radius: 0px;
    padding: 10px 15px;
    min-height: 50px;
    font-size: 17px;
}

.date-filter {
    .btn {
        background: none;
        padding: 10px 20px;
        min-width: 130px;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'ibm-plex-mono', sans-serif;
        color: black;
    }

    .btn:hover, .btn.btn-selected {
        background: black;
        color: white;
    }

    .btn.btn-disabled {
        color: #ccc;
    }

    input.btn {
        min-width: 240px;
    }

    .btn.btn-disabled:hover {
        background: none;
    }

    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: black;
    }

    input:hover::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: white;
    }

    input.btn-selected::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: white;
    }

    input::-moz-placeholder { /* Firefox 19+ */
        color: black;
    }

    input:hover::-moz-placeholder {
        color: white;
    }

    input.btn-selected::-moz-placeholder {
        color: white;
    }

    input:-ms-input-placeholder { /* IE 10+ */
        color: black;
    }

    input:hover:-ms-input-placeholder {
        color: white;
    }

    input.btn-selected:-ms-input-placeholder {
        color: white;
    }

    input:-moz-placeholder { /* Firefox 18- */
        color: black;
    }

    input:hover:-moz-placeholder {
        color: white;
    }

    input.btn-selected:-moz-placeholder {
        color: white;
    }
}

.admin-bubble {
    width: 80px;
    border-radius: 50px;
    border: 1px solid theme-color("primary");
    padding: 25px 0px;
    color: black;
    display: block;
    margin: 0px auto 10px;
}

.download-buttons {
    .btn {
        min-width: 250px;
    }
}

.tour-tile {
    .image-box {
        position: relative;
        display: inline-block;
        max-width: 300px;
    }
    .image-box .tour-popout-btn {
        position: absolute;
        right: 10px;
        bottom: 10px;
        background-color: black;
        color: white;
        font-size: 20px;
        line-height: .2;
        padding: 5px;
        padding-bottom: 15px;
        cursor: pointer;
    }
    .tour-edit-btn, .tour-version-dropdown, .tour-live-btn {
        margin: 15px auto 5px auto;
        width: 100%;
        max-width: 300px;
        text-align: center;
        padding: 10px;
    }
    .tour-edit-btn {
        margin-top: 30px;
        background-color: black;
        color: white;
        font-family: 'ibm-plex-mono';
        padding: 0;
    }
    .tour-edit-btn a{
        color: white;
        text-decoration: none;
        width: 100%;
        height: 100%;
        padding: 10px;
        display: inline-block;
    }
    .tour-version-dropdown {
        margin: 0 auto;
        border: 1px solid black;
        cursor: pointer;
        font-family: 'ibm-plex-mono';
    }
    .version-dropdown-container {
        margin: 0 auto 5px auto;
        max-width: 300px;
        position: relative;
    }
    .tour-version-dropdown i{
        float: right;
        font-size: 20px;
        padding-right: 5px;
        padding-top: 2px;
    }
    .tour-live-btn {
        margin: 0 auto;
        border: none;
        background-color: #F4CB00;
        padding: 0;
    }
    .tour-live-btn a{
        display:inline-block;
        height: 100%;
        width: 100%;
        padding: 10px;
        text-decoration: none;
        color: white;
        font-family: 'ibm-plex-mono';
    }
}
.border-3 {
    border-width:3px !important;
}

.font-ibm-mono
{
    font-family: 'ibm-plex-mono';
}

// version menu

.version-menu
{
	list-style-type: none;
	padding:0px;
	padding-inline-start: 0px;
    font-family: 'ibm-plex-mono';
    position:absolute;
    width: 100%;
    z-index:100;
}

.version-menu>li>a
{
    display:flex;
    justify-content: space-between;
    text-decoration: none;
    color:black;
    padding: .5em 1em;
    background: white;
    border: 1px solid black;
    border-top: none;
}

.version-menu>li>a:hover {
    background-color: #eee;
}

.version-menu>li>a.current
{
    background-color: #f3cb00;
}


#create-draft-window
{
    width:33%;
    min-width: 550px;
    border: 1px solid #f4cb00;
    background:#f2f3f3;
    padding: 3em 2em;
    border-radius: 6px;
    font-family: 'ibm-plex-mono';
    margin: 0px auto;
}

#create-draft-window input
{
    width: 100%;
    border: 1px solid black;
    background-color: #FFF;
    padding: .5em 1em;
}

#create-draft-window button[type="submit"]
{
    background: #f4cb00;
    font-weight:900;

}

.img-placeholder
{
    border: 1px solid black;
    width:100%;
    text-align: center;
    font-size: 5em;
    font-weight:100;
    display:flex;
    justify-content: center;
    align-items: center;
}

.img-placeholder:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
[x-cloak] { display: none; }
// update tour menu for index screens
.tour-list>div:nth-child(4n)>div.image-box>div.update-tour-menu>div[x-show="editMode"]>form {
    right: 5%;
}
.update-tour-menu
{
    display:block;
    flex-direction: column;
    margin-top: -40px;
}
.update-tour-menu>div[class*="d-flex"]
{
    justify-content: flex-end;
    margin-right:20px;

}
.update-tour-toggle
{
    margin: 0px;
    padding: 0px;
    border:none;
    background:black;
    color: white;
    display:inline-block;
}

.update-tour-menu>div>form
{
    position:absolute;
    background:white;
    border: 3px solid black;
    padding: 1em;
    width: 150%;
    text-align: left;
    z-index: 1000;
}

.update-tour-menu>div>form>input, .update-tour-menu>div>form>div>div>input
{
    border: 1px solid black;
    background:white;
    padding:.4em .5em;
    width:100%;
    display:block;
}
@media (max-width: 496px) {
    #admin-content {
        padding-left: 15px;
    }

    .date-filter {
        margin-bottom: 10px;
        width: 100%;

        .btn {
            min-width: 100px;
            font-size: 13px;
            padding: 10px;
        }
    }
}
