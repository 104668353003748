// Variables
@import "variables";

body#GoTrivia {
    
    // Headers
    h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
        font-family: "itc-american-typewriter", serif;
        font-weight: 700;
        color: #333;
    }

    h1 {
        font-size: 32px;
    }

    .tour-content h1, #screen-window h1 {
        text-transform: none;
    }

    button.btn {
        font-family: 'futura-pt', sans-serif !important;
    }

    .btn.btn-success, .btn.btn-submit {
        background: black !important;
        border-color: black !important;
        border-radius: 0px !important;
        color: white !important;
        // text-transform: uppercase;
    }

    #menu-toggle i {
        color: white;
    }

    label.btn.btn-default {
        font-family: "itc-american-typewriter", serif;
        text-transform: none;
        font-weight: 600;
        font-size: 18px;
    }

    #content .option {
        font-family: "itc-american-typewriter", serif;
        text-transform: none;
        font-weight: 600;
    }

    .swal2-popup button.swal2-confirm.swal2-styled {
        background: black;
        border-radius: 0px;
        font-family: 'futura-pt', sans-serif !important;
        text-transform: uppercase;
        color: white;
    }
}