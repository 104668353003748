// Variables
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-vue/dist/bootstrap-vue.css";

// Lightpick
@import '~lightpick/css/lightpick.css';

// Auth Admin
@import './auth/index.scss';

// Admin Styles
@import './admin.scss';

// Fonts
@font-face {
    font-family: "CP Mono";
    src: url(https://brews.nyc3.cdn.digitaloceanspaces.com/CPMono_v07%20Plain.otf) format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: "CP Mono";
    src: url(https://brews.nyc3.cdn.digitaloceanspaces.com/CPMono_v07%20Black.otf) format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: "CP Mono";
    src: url(https://brews.nyc3.cdn.digitaloceanspaces.com/CPMono_v07%20Bold.otf) format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: "CP Mono";
    src: url(https://brews.nyc3.cdn.digitaloceanspaces.com/CPMono_v07%20ExtraLight.otf) format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: "CP Mono";
    src: url(https://brews.nyc3.cdn.digitaloceanspaces.com/CPMono_v07%20Light.otf) format('opentype');
    font-weight: 200;
}

html, body {
    min-height: 100vh;
}

main {
    position: relative;
}

.tour-content {
    padding-bottom: 100px;
    min-height: 90vh;
}

.navbar-primary-bg .navbar-brand img {
    padding: 5px 0px;
}

// Body scrollfix
body.modal-open {
    position: fixed;
    overflow-y: hidden;
    width: 100vw;
}

.modal-open .modal {
    -webkit-overflow-scrolling: touch;
}

#feedback {
    border: 2px solid $body-color;
    color: $body-color;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
}

main.py-4 {
    padding-top: 90px !important;
}

// Navbar
.navbar-primary-bg {
    background-color: theme-color("primary");
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    text-align: center;
    z-index: 999;
    position: fixed;
    left: 0px;
    width: 100%;

    .navbar-brand {
        padding: 0px;
        width: 50%;
        margin: auto;

        img {
            max-height: 70px;
            margin-top: -7px;
            margin-bottom: -7px;
        }
    }
}

.swal2-popup.support-popup {
    padding: 20px 0px;

    .swal2-title {
        color: black;
        font-size: 38px;
    }

    .swal2-content {
        padding: 0px 20px;
    }

    p {
        text-align: left;
        font-size: 18px;

        span {
            color: #F4CB00;
            margin-right: 5px;
            display: block;
            float: left;
            margin-bottom: 65px;
        }

        strong {
            color: black;
        }
    }

    p:first-child {
        text-align: center;
    }
}

// Headers
h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-family: "bebas-neue", sans-serif;
    color: #333;
}

h1 {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
}

// Text
p {
    font-size: 110%;
}


// Buttons
.btn.btn-submit {
    border-radius: 30px;
    padding: 12px;
    font-size: 22px;
    font-weight: 700;
    color: black;
}

.btn.btn-link {
    font-size: 18px;
    color: $gray-500;
}

.btn-multi {
    .btn.btn-default {
        font-family: "bebas-neue", sans-serif;
        color: #333;
        border: 2px solid #333;
        margin-bottom: 10px;
        border-radius: 0px;
        font-size: 22px;
        line-height: 22px;
        padding: 15px 10px;
    }

    .btn.btn-default.active {
        background-color: theme-color("success");
        border-color: theme-color("success");
    }
}

.btn-success:hover, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    background-color: theme-color("success");
    border-color: theme-color("success");
}


// Inputs
input.form-control, textarea.form-control {
    border: $default-border;
    border-radius: 25px;
    padding: 15px;
    min-height: 50px;
    font-size: 17px;
}

input.form-control::placeholder, textarea.form-control::placeholder {
    color: $gray-500;
}

.custom-control{

	&.material-checkbox{
		--color: #26a69a;

		.material-control-input{
			display: none;
			&:checked~.material-control-indicator{
				border-color: var(--color);
				transform: rotateZ(45deg) translate(1px, -5px);
				width: 10px;
				border-top: 0px solid #fff;
				border-left: 0px solid #fff;
			}
		}
		.material-control-indicator{
			display: inline-block;
			position: absolute;
			top: 4px;
			left: 2px;
			width: 22px;
			height: 22px;
			border: 3px solid black;
			transition: .3s;
        }
        
        .material-control-description{
            float: left;
            padding-left: 15px;
        }
	}

	&.fill-checkbox{
		--color: #26a69a;

		.fill-control-input{
			display: none;
			&:checked~.fill-control-indicator{
				background-color: var(--color);
				border-color: var(--color);
				background-size: 80%;
			}
		}
		.fill-control-indicator{
			border-radius: 3px;
			display: inline-block;
			position: absolute;
			top: 4px;
			left: 0;
			width: 16px;
			height: 16px;
			border: 2px solid #aaa;
			transition: .3s;
			background: transperent;
			background-size: 0%;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
		}
	}

	&.overflow-checkbox{
		.overflow-control-input{
			display: none;

			&:checked~.overflow-control-indicator{
				&::after{
					transform: rotateZ(45deg) scale(1);
					top: -6px;
					left: 5px;
				}

				&::before{
					opacity: 1;
				}
			}
		}
		.overflow-control-indicator{
			border-radius: 3px;
			display: inline-block;
			position: absolute;
			top: 4px;
			left: 0;
			width: 16px;
			height: 16px;
			border: 2px solid #aaa;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 16px;
				height: 16px;
				transition: .3s;
				transform: rotateZ(90deg) scale(0);
				width: 10px;
				border-bottom: 4px solid #aaa;
				border-right: 4px solid #aaa;
				border-radius: 3px;
				top: -2px;
				left: 2px;
			}

			&::before{
				content: '';
				display: block;
				position: absolute;
				width: 16px;
				height: 16px;
				transition: .3s;
				width: 10px;
				border-right: 7px solid #fff;
				border-radius: 3px;
				transform: rotateZ(45deg) scale(1);
				top: -4px;
				left: 5px;
				opacity: 0;
			}
		}
	}

	&.material-switch{
		--color: #26a69a;
		padding-left: 0;

		.material-switch-control-input{
			display: none;
			&:checked~.material-switch-control-indicator{

				&::after{
					background-color: var(--color);
					left: 17px;
				}
			}
		}
		.material-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 16px;
			background: #ddd;
			border-radius: 16px;
			transition: .3s;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				transition: .3s;
				top: -1px;
				left: -1px;
				background: #fdfdfd;
				box-shadow: 0 2px 10px #aaa;
			}
		}
	}

	&.ios-switch{
		--color: #4cd964;
		padding-left: 0;

		.ios-switch-control-input{
			display: none;

			&:active~.ios-switch-control-indicator{
				&::after{
					width: 20px;
				}
			}

			&:checked{
				~.ios-switch-control-indicator{
					border: 10px solid var(--color);

					&::after{
						top: -8px;
						left: 4px;
					}
				}

				&:active~.ios-switch-control-indicator{
					&::after{
						left: 0px;
					}
				}
			}
		}
		.ios-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 20px;
			background: #fff;
			border-radius: 16px;
			transition: .3s;
			border: 2px solid #ddd;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 16px;
				height: 16px;
				border-radius: 16px;
				transition: .3s;
				top: 0px;
				left: 0px;
				background: #fff;
				box-shadow: 0 0 2px #aaa, 0 2px 5px #999;
			}
		}
	}

	&.border-switch{
		--color: #4cd964;
		padding-left: 0;

		.border-switch-control-input{
			display: none;
			&:checked~.border-switch-control-indicator{
				border-color: var(--color);

				&::after{
					left: 14px;
					background-color: var(--color);
				}
			}
		}
		.border-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 20px;
			background: #fff;
			border-radius: 16px;
			transition: .3s;
			border: 2px solid #ccc;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				transition: .3s;
				top: 2px;
				left: 2px;
				background: #ccc;
			}
		}
	}

	&.teleport-switch{
		--color: #4cd964;
		padding-left: 0;

		.teleport-switch-control-input{
			display: none;
			&:checked~.teleport-switch-control-indicator{
				border-color: var(--color);

				&::after{
					left: -14px;
				}

				&::before{
					right: 2px;
					background-color: var(--color);
				}
			}
		}
		.teleport-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 20px;
			background: #fff;
			border-radius: 16px;
			transition: .3s;
			border: 2px solid #ccc;
			overflow: hidden;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				transition: .3s;
				top: 2px;
				left: 2px;
				background: #ccc;
			}

			&::before{
				content: '';
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				transition: .3s;
				top: 2px;
				right: -14px;
				background: #ccc;
			}
		}
	}
}

.list-group{
	&:first-child .list-group-control:first-child {
		border-top-left-radius: .25rem;
		border-top-right-radius: .25rem;
	}

	&::last-child .list-group-control:last-child {
		border-bottom-left-radius: .25rem;
		border-bottom-right-radius: .25rem;
	}

	&.list-group-flush{
		&:last-child{
			.list-group-control:last-child {
				border-bottom: 0;
				margin-bottom: 0;
			}
		}

		&:first-child{
			.list-group-control:first-child {
				border-top: 0;
			}
		}

		.list-group-control {
			border-right: 0;
			border-left: 0;
			border-radius: 0;
		}
	}
}


// Sweetalerts 2 overrides
div.form-check {
    font-size: 18px;
    font-weight: 700;
    color: black;

    input.form-check-input {
        background: none;
    }
}

span[role="alert"] {
    padding-left: 15px;
}

// Leaderboard
div.leaderboard-header {
    font-size: 10px;
    font-weight: 600;
    color: black;
    text-transform: uppercase;

    div {
        padding-bottom: 5px;
        padding-left: 0px;
        padding-right: 0px;
        border-bottom: 1px solid #888;
    }
}

div.leaderboard-body {
    font-weight: 300;

    div {
        padding: 5px 0px;
        border-left: 1px solid #888;
        border-bottom: 1px solid #888;
    }

    div:last-child {
        border-right: 1px solid #888;
    }
}

div.leaderboard-body.current-team {
    font-weight: 800;
    
    div {
        background: theme-color("success");
    }
}

.leaderboard-body-wrapper {
    position: relative;
}

.leaderboard-body-wrapper:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -1px;
    pointer-events: none;
    background-image: linear-gradient(to bottom, 
                      rgba(255,255,255, 0), 
                      rgba(255,255,255, 1) 90%);
    width: calc(100% + 2px);
    height: 4em;
}

.share-icon {
    .fab {
        font-size: 70px;
    }

    .fab.fa-facebook {
        color: #3b5998 !important;
    }

    .fab.fa-twitter {
        color: #00aced !important;
    }

    .fab.fa-pinterest {
       color: #cb2027 !important; 
    }
}

.leaderboard-inactive {
    display: none;
}

.leaderboard-active {
    display: block;
}

#total {
    .leaderboard-body-wrapper:after {
        display: none;
    }
}

.answer-feedback-modal img.swal2-image {
    max-width: 190px;
}

.answer-feedback-modal .swal2-actions button {
    width: 100%;
}

.answer-feedback-modal {
    box-shadow: 0px 0px 15px -4px #000;


    .swal2-input {
        border-radius: 30px;
    }

    .swal2-content {
        padding: 0 .25rem;
    }

    .swal2-title {
        font-size: 2.25rem;
        color: #333;
        font-weight: 500;
        line-height: 1.2;

    }

    .modal-container {
        display: flex;
        flex-direction: column;
        font-family: 'CP Mono';
    }

    .correct-response {
        padding: 10px 0px 30px;

        dt {
            font-size: 1.25rem;
        }
    }

    .current-status {
        display: flex;
        justify-content: space-between;
        align-content: space-evenly;

        .column {
            padding: 0px 10px;
            text-align: left;
        }
        
        dt {
            font-weight: 400;
            font-size: 1.6rem;
    
            span {
                font-size: 1.2rem
            }
        }
    }

    dd {
        color: #F4CB00;
        font-weight: 600;
        text-transform: uppercase;
        font-size: .9rem;
    }
 
    dt {
        font-weight: 400;
    }

    .swal2-actions {
        button.swal2-confirm {
            font-size: 1.25rem;
        }
    }
}

// Notification Toast
.swal2-container.notification-toast {
    width: 100vw;
    background-color: black !important;

    .swal2-popup {
        background-color: transparent !important;
        color: white !important;
        box-shadow: none;
        outline: none;

        * {
            color: white !important;
            font-size: 14px;
        }

        strong {
            color: #F4CB00 !important;
        }
    }
}

div.join-tour .alert {
    text-align:center !important;
}

// Go Trivia overrides
@import './gotrivia.scss';